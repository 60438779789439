/* purgecss start ignore */

.awssld__box,.awssld__content,.awssld__container figure{
  display:block;
  position:absolute;
  width:100%;
  height:100%;
  top:0;
  left:0
}

.awssld__bullets button,.awssld__controls button{
  outline-color:0;
  outline-style:none;
  outline-width:0
}

.awssld{
  --organic-arrow-thickness: 4px;
  --organic-arrow-height: 40px;
  --slider-height-percentage: 60%;
  --loader-bar-color: #656565;
  --loader-bar-height: 6px;
  --control-button-width: 10%;
  --control-button-height: 25%;
  --control-button-opacity: 0.5;
  --control-button-hover-opacity: 0.75;
  --control-button-background: transparent;
  --transition-bezier: cubic-bezier(0.5, 0.075, 0.25, 0.95);
  --slider-transition-duration: 575ms;
  --organic-arrow-color: #8C8C8C;
  --organic-arrow-border-radius: 0;
  --control-bullet-color: #8C8C8C;
  --control-bullet-active-color: #8C8C8C;
  --content-background-color: #8C8C8C
}

.awssld{
  display:block;
  position:relative;
  width:100%;
  max-width:100%
}

.awssld__wrapper{
  display:block;
  overflow:hidden;
  position:relative;
  height:100%;
  width:100%
}

.awssld__container{
  display:block;
  width:100%;
  height:0;
  padding-bottom:var(--slider-height-percentage)
}

@media all and (max-width: 500px){
  .awssld__container{
    padding-bottom:calc(var(--slider-height-percentage)*1.25)
  }
}

.awssld__startUp{
  background-color:red;
  height:100%;
  width:100%
}

.awssld__startUp>div{
  height:100%;
  width:100%;
  display:flex;
  align-items:center;
  justify-content:center
}

.awssld__startUp img{
  width:35%;
  height:auto
}

.awssld__content{
  background-color:var(--content-background-color);
  overflow:hidden;
  display:flex;
  align-items:center;
  justify-content:center
}

.awssld__content>img,.awssld__content>video{
  height:100%;
  top:0;
  left:0
}

.awssld__controls button{
  display:flex;
  align-items:center;
  justify-content:center;
  width:var(--control-button-width);
  height:var(--control-button-height);
  position:absolute;
  z-index:10;
  top:calc(50% - .5*var(--control-button-height));
  border:none;
  background-color:var(--control-button-background);
  color:#fff;
  cursor:pointer
}

.awssld__controls button .awssld__controls__arrow-left,.awssld__controls button .awssld__controls__arrow-right{
  opacity:var(--control-button-opacity)
}

.awssld__controls button:hover .awssld__controls__arrow-left,.awssld__controls button:hover .awssld__controls__arrow-right{
  opacity:var(--control-button-opacity-hover)
}

.awssld__controls--active .awssld__controls__arrow-left{
  opacity:var(--control-button-opacity-hover);
  transform:translate3d(-100%, 0, 0)
}

.awssld__controls--active .awssld__controls__arrow-right{
  opacity:var(--control-button-opacity-hover);
  transform:translate3d(100%, 0, 0)
}

.awssld__controls--hidden{
  display:none
}

@media all and (max-width: 520px){
  .awssld__controls{
    visibility:hidden
  }
}

.awssld__bar{
  display:block;
  width:100%;
  height:var(--loader-bar-height);
  background-color:var(--loader-bar-color);
  position:absolute;
  top:0;
  left:0;
  z-index:101;
  transition:transform 3000ms var(--transition-bezier);
  transform:translate3d(-100%, 0, 0)
}

.awssld__bar--active{
  transform:translate3d(-20%, 0, 0)
}

.awssld__bar--end{
  transition-duration:300ms;
  transform:translate3d(0, 0, 0)
}

.awssld__next{
  right:0
}

.awssld__prev{
  left:0
}

.awssld__box{
  z-index:1;
  visibility:hidden
}

.awssld--animated{
  will-change:transform;
  visibility:visible
}

.awssld--animated-mobile{
  will-change:transform;
  transition:transform 325ms cubic-bezier(0.15, 0.65, 0.1, 1)
}

.awssld--active{
  visibility:visible;
  z-index:2;
  transform:translate3d(0, 0, 0)
}

.awssld--moveRight,.awssld--moveLeft{
  -webkit-backface-visibility:hidden;
  backface-visibility:hidden
}

.awssld--moveRight{
  -webkit-animation:slideFromRight var(--slider-transition-duration) both var(--transition-bezier);
  animation:slideFromRight var(--slider-transition-duration) both var(--transition-bezier)
}

.awssld--moveLeft{
  -webkit-animation:slideFromLeft var(--slider-transition-duration) both var(--transition-bezier);
  animation:slideFromLeft var(--slider-transition-duration) both var(--transition-bezier)
}

.awssld--exit{
  z-index:0
}

.awssld--exit.awssld--moveLeft{
  -webkit-animation:slideToLeft var(--slider-transition-duration) both var(--transition-bezier);
  animation:slideToLeft var(--slider-transition-duration) both var(--transition-bezier)
}

.awssld--exit.awssld--moveRight{
  -webkit-animation:slideToRight var(--slider-transition-duration) both var(--transition-bezier);
  animation:slideToRight var(--slider-transition-duration) both var(--transition-bezier)
}

.awssld--first .awssld__prev{
  visibility:hidden
}

.awssld--last .awssld__next{
  visibility:hidden
}

.awssld--fill-parent{
  position:absolute !important;
  width:100% !important;
  height:100% !important;
  top:0;
  left:0
}

.awssld--fill-parent .awssld__container{
  height:100%;
  padding:0
}

.awssld__bullets{
  position:absolute;
  bottom:-40px;
  width:100%;
  display:flex;
  align-items:center;
  justify-content:center
}

.awssld__bullets button{
  padding:0;
  display:block;
  width:16px;
  height:16px;
  margin:5px;
  border-radius:50%;
  background:var(--control-bullet-color);
  text-indent:-9999px;
  overflow:hidden;
  cursor:pointer;
  border:none;
  transition:transform .225s cubic-bezier(0.8, 1.35, 0.75, 1.45),background-color .175s ease-out
}

.awssld__bullets button:hover{
  transform:scale(1.2)
}

.awssld__bullets .awssld__bullets--loading{
  transform:scale(1.2)
}

.awssld__bullets .awssld__bullets--active{
  transform:scale(1.5);
  background:var(--control-bullet-active-color)
}

.awssld__bullets .awssld__bullets--active:hover{
  transform:scale(1.5)
}

.awssld__controls__arrow-left,.awssld__controls__arrow-right{
  width:100%;
  height:var(--organic-arrow-height);
  position:relative;
  display:block;
  transition:transform .2s ease-out .125s,opacity .2s ease-out
}

.awssld__controls__arrow-left:before,.awssld__controls__arrow-left:after,.awssld__controls__arrow-right:before,.awssld__controls__arrow-right:after{
  content:" ";
  position:absolute;
  right:calc(50% - (
            0.7071 * (var(--organic-arrow-height) +
                  var(--organic-arrow-thickness))
          )/2);
  height:100%;
  border-radius:var(--organic-arrow-border-radius);
  width:var(--organic-arrow-thickness);
  background-color:var(--organic-arrow-color);
  transition:transform .15s ease-out,background-color .15s ease-out
}

.awssld__controls__arrow-left:before,.awssld__controls__arrow-right:before{
  transform-origin:100% 100% 0;
  top:-50%;
  transform:rotate(-45deg)
}

.awssld__controls__arrow-left:after,.awssld__controls__arrow-right:after{
  transform-origin:100% 0% 0;
  top:50%;
  transform:rotate(45deg)
}

.awssld__controls__arrow-right--active{
  transform:translate3d(100%, 0, 0)
}

.awssld__controls__arrow-right--active:after{
  transform:rotate(90deg) translate3d(50%, 0, 0) !important
}

.awssld__controls__arrow-right--active:before{
  transform:rotate(-90deg) translate3d(50%, 0, 0) !important
}

.awssld__controls__arrow-left:before,.awssld__controls__arrow-left:after{
  right:auto;
  left:calc(50% - (
            0.7071 * (var(--organic-arrow-height) +
                  var(--organic-arrow-thickness))
          )/2)
}

.awssld__controls__arrow-left:before{
  transform-origin:0 100% 0;
  top:-50%;
  transform:rotate(45deg)
}

.awssld__controls__arrow-left:after{
  transform-origin:0 0 0;
  top:50%;
  transform:rotate(-45deg)
}

.awssld__controls__arrow-left--active{
  transform:translate3d(-100%, 0, 0)
}

.awssld__controls__arrow-left--active:after{
  transform:rotate(-90deg) translate3d(-50%, 0, 0) !important
}

.awssld__controls__arrow-left--active:before{
  transform:rotate(90deg) translate3d(-50%, 0, 0) !important
}

.awssld__controls button:hover .awssld__controls__arrow-left:before{
  opacity:1;
  transform:rotate(30deg)
}

.awssld__controls button:hover .awssld__controls__arrow-left:after{
  opacity:1;
  transform:rotate(-30deg)
}

.awssld__controls button:hover .awssld__controls__arrow-right:before{
  opacity:1;
  transform:rotate(-30deg)
}

.awssld__controls button:hover .awssld__controls__arrow-right:after{
  opacity:1;
  transform:rotate(30deg)
}

.awssld__timer{
  --timer-delay: 2000ms;
  --timer-release: 200ms;
  --timer-height: 4px;
  --timer-background-color: rgba(0, 0, 0, 0.15);
  width:100%;
  height:var(--timer-height);
  background-color:var(--timer-background-color);
  position:absolute;
  top:0;
  left:0;
  z-index:100;
  transform:translateX(-100%)
}

.awssld__timer--animated{
  will-change:transform;
  transition:transform var(--timer-delay) linear
}

.awssld__timer--run{
  transform:translateX(0)
}

.awssld__timer--fast{
  transition:transform calc(var(--timer-release)/2) linear;
  transform:translateX(-0.00001px)
}

.awssld__timer--end{
  transform:translateX(-0.0001px);
  transition:transform var(--timer-release) linear
}

.awssld__timer--hidden{
  display:none
}

@-webkit-keyframes slideFromLeft{
  from{
    transform:translate3d(-100%, 0, 0)
  }

  to{
    transform:translate3d(0, 0, 0)
  }
}

@keyframes slideFromLeft{
  from{
    transform:translate3d(-100%, 0, 0)
  }

  to{
    transform:translate3d(0, 0, 0)
  }
}

@-webkit-keyframes slideFromRight{
  from{
    transform:translate3d(100%, 0, 0)
  }

  to{
    transform:translate3d(0, 0, 0)
  }
}

@keyframes slideFromRight{
  from{
    transform:translate3d(100%, 0, 0)
  }

  to{
    transform:translate3d(0, 0, 0)
  }
}

@-webkit-keyframes slideToLeft{
  from{
    transform:translate3d(0, 0, 0)
  }

  to{
    transform:translate3d(-100%, 0, 0)
  }
}

@keyframes slideToLeft{
  from{
    transform:translate3d(0, 0, 0)
  }

  to{
    transform:translate3d(-100%, 0, 0)
  }
}

@-webkit-keyframes slideToRight{
  from{
    transform:translate3d(0, 0, 0)
  }

  to{
    transform:translate3d(100%, 0, 0)
  }
}

@keyframes slideToRight{
  from{
    transform:translate3d(0, 0, 0)
  }

  to{
    transform:translate3d(100%, 0, 0)
  }
}

/* purgecss end ignore */